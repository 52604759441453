<template>
<main>
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div v-if="is_ready" class="col-xl-10">
            <div class="card">
              <div class="card-header">
                <h1 id="disease-title" class="h4 mt-2">{{disease.name}}</h1>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="h6">Erreger</h3>
                    <ul>
                      <li v-for="(pathogen) in pathogens.names" :key="pathogen.id" :id=pathogen.id><a :href="'/erreger/'+ pathogen">{{pathogen}}</a></li>
                    </ul>
                  </div>
                </div>   
              </div>
              <div class="card-tab">
                <h2 class="h5 mt-1 mb-3">Behandlung</h2>
                <ul class="nav nav-tabs nav-justified card-header-tabs" id="age-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="adult-tab" data-toggle="tab" href="#adult" role="tab" aria-controls="adult" aria-selected="true">Erwachsen</a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" id="child-tab" data-toggle="tab" href="#child" role="tab" aria-controls="child" aria-selected="false">Kind</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="age-tab-content">
                  <div class="tab-pane fade show active" id="adult" role="tabpanel" aria-labelledby="adult-tab">
                    <div v-if="prio_items.prio_1_adult_first.medicine_names.length != 0" class="box">
                      <div class="box-body">
                        <div class="box-separator first-choice">
                          <h6 class="mb-0">1. Priorität</h6>
                        </div>
                        <div v-for="(item, index) in prio_items.prio_1_adult_first.medicine_names" :key=item.id >
                          <p v-if="index < prio_items.prio_1_adult_first.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_adult_first.dosages[index]}} <span class="box-text-plus">plus</span></p>
                          <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_adult_first.dosages[index]}} </p>
                        </div>
                        <div v-if="prio_items.prio_1_adult_second.medicine_names.length != 0">
                          <div class="box-separator or-choice">
                            <h6 class="mb-0">oder</h6>
                          </div>
                          <div v-for="(item, index) in prio_items.prio_1_adult_second.medicine_names" :key=item.id >
                            <p v-if="index < prio_items.prio_1_adult_second.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_adult_second.dosages[index]}} <span class="box-text-plus">plus</span></p>
                            <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_adult_second.dosages[index]}} </p>
                          </div>
                        </div>
                      </div> 
                    </div>

                    <div v-if="prio_items.prio_2_adult_first.medicine_names.length != 0" class="box">
                      <div class="box-body">
                        <div class="box-separator second-choice">
                          <h6 class="mb-0">2. Priorität</h6>
                        </div>
                        <div v-for="(item, index) in prio_items.prio_2_adult_first.medicine_names" :key=item.id >
                          <p v-if="index < prio_items.prio_2_adult_first.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_adult_first.dosages[index]}} <span class="box-text-plus">plus</span></p>
                          <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_adult_first.dosages[index]}} </p>
                        </div>
                        <div v-if="prio_items.prio_2_adult_second.medicine_names.length != 0">
                          <div class="box-separator or-choice">
                            <h6 class="mb-0">oder</h6>
                          </div>
                          <div v-for="(item, index) in prio_items.prio_2_adult_second.medicine_names" :key=item.id >
                            <p v-if="index < prio_items.prio_2_adult_second.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_adult_second.dosages[index]}} <span class="box-text-plus">plus</span></p>
                            <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_adult_second.dosages[index]}} </p>
                          </div>
                        </div>
                      </div> 
                    </div>

                    <div v-if="prio_items.prio_3_adult_first.medicine_names.length != 0" class="box">
                      <div class="box-body">
                        <div class="box-separator third-choice">
                          <h6 class="mb-0">3. Priorität</h6>
                          {{prio_items.prio_3_adult_first.medicine_names.length}}
                        </div>
                        <div v-for="(item, index) in prio_items.prio_3_adult_first.medicine_names" :key=item.id >
                          <p v-if="index < prio_items.prio_3_adult_first.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_adult_first.dosages[index]}} <span class="box-text-plus">plus</span></p>
                          <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_adult_first.dosages[index]}} </p>
                        </div>
                        <div v-if="prio_items.prio_3_adult_second.medicine_names.length != 0">
                          <div class="box-separator or-choice">
                            <h6 class="mb-0">oder</h6>
                          </div>
                          <div v-for="(item, index) in prio_items.prio_3_adult_second.medicine_names" :key=item.id >
                            <p v-if="index < prio_items.prio_3_adult_second.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_adult_second.dosages[index]}} <span class="box-text-plus">plus</span></p>
                            <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_adult_second.dosages[index]}} </p>
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div class="tab-pane fade" id="child" role="tabpanel" aria-labelledby="child-tab">
                    <div v-if="prio_items.prio_1_child_first.medicine_names.length != 0" class="box">
                      <div class="box-body">
                        <div class="box-separator first-choice">
                          <h6 class="mb-0">1. Priorität</h6>
                        </div>
                        <div v-for="(item, index) in prio_items.prio_1_child_first.medicine_names" :key=item.id >
                          <p v-if="index < prio_items.prio_1_child_first.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_child_first.dosages[index]}} <span class="box-text-plus">plus</span></p>
                          <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_child_first.dosages[index]}} </p>
                        </div>
                        <div v-if="prio_items.prio_1_child_second.medicine_names.length != 0">
                          <div class="box-separator or-choice">
                            <h6 class="mb-0">oder</h6>
                          </div>
                          <div v-for="(item, index) in prio_items.prio_1_child_second.medicine_names" :key=item.id >
                            <p v-if="index < prio_items.prio_1_child_second.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_child_second.dosages[index]}} <span class="box-text-plus">plus</span></p>
                            <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_1_child_second.dosages[index]}} </p>
                          </div>
                        </div>
                      </div> 
                    </div>

                    <div v-if="prio_items.prio_2_child_first.medicine_names.length != 0" class="box">
                      <div class="box-body">
                        <div class="box-separator second-choice">
                          <h6 class="mb-0">2. Priorität</h6>
                        </div>
                        <div v-for="(item, index) in prio_items.prio_2_child_first.medicine_names" :key=item.id >
                          <p v-if="index < prio_items.prio_2_child_first.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_child_first.dosages[index]}} <span class="box-text-plus">plus</span></p>
                          <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_child_first.dosages[index]}} </p>
                        </div>
                        <div v-if="prio_items.prio_2_child_second.medicine_names.length != 0">
                          <div class="box-separator or-choice">
                            <h6 class="mb-0">oder</h6>
                          </div>
                          <div v-for="(item, index) in prio_items.prio_2_child_second.medicine_names" :key=item.id >
                            <p v-if="index < prio_items.prio_2_child_second.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_child_second.dosages[index]}} <span class="box-text-plus">plus</span></p>
                            <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_2_child_second.dosages[index]}} </p>
                          </div>
                        </div>
                      </div> 
                    </div>

                    <div v-if="prio_items.prio_3_child_first.medicine_names.length != 0" class="box">
                      <div class="box-body">
                        <div class="box-separator third-choice">
                          <h6 class="mb-0">3. Priorität</h6>
                        </div>
                        <div v-for="(item, index) in prio_items.prio_3_child_first.medicine_names" :key=item.id >
                          <p v-if="index < prio_items.prio_3_child_first.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_child_first.dosages[index]}} <span class="box-text-plus">plus</span></p>
                          <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_child_first.dosages[index]}} </p>
                        </div>
                        <div v-if="prio_items.prio_3_child_second.medicine_names.length != 0">
                          <div class="box-separator or-choice">
                            <h6 class="mb-0">oder</h6>
                          </div>
                          <div v-for="(item, index) in prio_items.prio_3_child_second.medicine_names" :key=item.id >
                            <p v-if="index < prio_items.prio_3_child_second.medicine_names.length - 1"><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_child_second.dosages[index]}} <span class="box-text-plus">plus</span></p>
                            <p v-else><a :href="'/medikament/' + item">{{item}}</a> {{prio_items.prio_3_child_second.dosages[index]}} </p>
                          </div>
                        </div>
                      </div> 
                    </div>             
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-xl-10">
            <p>Daten werden geladen ...</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {ref} from "vue";

async function getAPIElement(target, ids="", name_filter=null) {
  let token = document.cookie.match(new RegExp('(^| )' + 'directus_api_auth_token' + '=([^;]+)'));
  let url = '';

  if(name_filter == null) {
    url = 'https://directus.comkom.de/items/'+ target +'/' + ids + '?filter[status]=published&fields=*.*';
  } else {
    url = 'https://directus.comkom.de/items/'+ target +'/' + ids + '?filter[status]=published&fields=*.*&filter[name]=' + name_filter;
  }

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token[2],
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  return await response.json()
}

async function getMedicamentNames(response) {
  let names = [];
  response.data.forEach(element => {
    names.push(element.name);
  });
  return names;
}

export default {
  name: 'AsyncSingleDisease',
  props: ['getLocation'],
  data() {
    return {
      prio_items: {
        prio_1_adult_first: {
          medicine_names: [], 
          dosages:[]
        },
        prio_2_adult_first: {
          medicine_names: [], 
          dosages:[]
        },
        prio_3_adult_first: {
          medicine_names: [], 
          dosages:[]
        },
        prio_1_adult_second: {
          medicine_names: [], 
          dosages:[]
        },
        prio_2_adult_second: {
          medicine_names: [], 
          dosages:[]
        },
        prio_3_adult_second: {
          medicine_names: [], 
          dosages:[]
        },
        prio_1_child_first: {
          medicine_names: [], 
          dosages:[]
        },
        prio_2_child_first: {
          medicine_names: [], 
          dosages:[]
        },
        prio_3_child_first: {
          medicine_names: [], 
          dosages:[]
        },
        prio_1_child_second: {
          medicine_names: [], 
          dosages:[]
        },
        prio_2_child_second: {
          medicine_names: [], 
          dosages:[]
        },
        prio_3_child_second: {
          medicine_names: [], 
          dosages:[]
        }
      },
      disease: {
        name: "",
        lat_name: "",
        acquisition: "",
        additional_information: ""
      },
      pathogens: {
        names: []
      },
      status: true,
      error: [],
      is_ready: false
    }
  },
  async created() {
    let token = document.cookie.match(new RegExp('(^| )' + 'directus_api_auth_token' + '=([^;]+)'));
    let is_login = document.cookie.match(new RegExp('(^| )' + 'antibiotika_app_login' + '=([^;]+)'))

    const prio_items = ref(null);

    if (is_login[2]) {
      try {
        const response_diseases = await getAPIElement('krankheiten', '', this.getLocation());

        this.disease.name = response_diseases.data[0].name;
        this.disease.lat_name = response_diseases.data[0].lat_name;
        this.disease.acquisition = response_diseases.data[0].erwerb;
        this.disease.additional_information = response_diseases.data[0].zusatzinformationen;

        let pathogen_ids = "";
        response_diseases.data[0].erreger.forEach(pathogen => {
          pathogen_ids += pathogen.erreger_id + ',';
        });

        const response_pathogens = await getAPIElement('erreger', pathogen_ids);

        let pathogen_names = [];
        response_pathogens.data.forEach((pathogen, index) => {
          pathogen_names[index] = pathogen.name;
        })

        this.pathogens.names = pathogen_names;
        

        let prio_ids = "";
        if(response_diseases.data[0].prio_erwachsene != null) {
          response_diseases.data[0].prio_erwachsene.forEach(element => {
            prio_ids += element.prio_erwachsene_id + ',';
          });
          await this.addPrioListToItems(response_diseases, await getAPIElement('prio_erwachsene', prio_ids), 'adult', prio_items);
        }

        if(response_diseases.data[0].prio_kinder != null) {
          prio_ids = "";
          response_diseases.data[0].prio_kinder.forEach(element => {
            prio_ids += element.prio_kinder_id + ',';
          });
          await this.addPrioListToItems(response_diseases, await getAPIElement('prio_kinder', prio_ids), 'child', prio_items);
        }
        this.is_ready= true;

      } catch (error) {
        this.status = false;
        this.error = error;
      } finally {
        
      }
    }
  },
  methods: {
    addPrioListToItems: async function(items, prios, type, prio_items) {
      let medicallist_1_ids = [];
      let ids_1 = "";
      let medicallist_2_ids = [];
      let ids_2 = "";
      prios.data.forEach((element) => {
        if(element.medikamentenliste_1 != null) {
          element.medikamentenliste_1.forEach(med => {
            ids_1 += med.medikamente_id + ",";
          });
          medicallist_1_ids[element.level - 1] = ids_1;
          ids_1 = "";
        }

        if(element.medikamentenliste_2 != null) {
          element.medikamentenliste_2.forEach(med => {
            ids_2 += med.medikamente_id + ",";
          });
          medicallist_2_ids[element.level - 1] = ids_2;
          ids_2 = "";
        }
      });
      let api_elements = ref(null);
      switch (type) {
        case 'adult':
          medicallist_1_ids.forEach(async (ids, index) => {
            switch (index) {
              case 0:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_1_adult_first = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'adult')
                };
                break;
              case 1:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_2_adult_first = { 
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'adult')
                };
                break;
              case 2:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_3_adult_first = { 
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'adult')
                };
                break;

              default:
                break;
            };        
          });

          medicallist_2_ids.forEach(async (ids, index) => {
            switch (index) {
              case 0:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_1_adult_second = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'adult')
                };
                break;
              case 1:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_2_adult_second = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'adult')
                };
                break;
              case 2:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_3_adult_second = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'adult')
                };
                break;

              default:
                break;
            };        
          });
          break;
        case 'child':
          medicallist_1_ids.forEach(async (ids, index) => {
            switch (index) {
              case 0:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_1_child_first = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'child')
                };
                break;
              case 1:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_2_child_first = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'child')
                };
                break;
              case 2:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_3_child_first = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'child')
                };
                break;

              default:
                break;
            };        
          });

          medicallist_2_ids.forEach(async (ids, index) => {
            switch (index) {
              case 0:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_1_child_second = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'child')
                };
                break;
              case 1:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_2_child_second = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'child')
                };
                break;
              case 2:
                api_elements = await getAPIElement('medikamente', ids);
                this.prio_items.prio_3_child_second = {
                  medicine_names: await getMedicamentNames(api_elements), 
                  dosages: await this.getDosages(api_elements, 'child')
                };
                break;

              default:
                break;
            };        
          });
          break;
      
        default:
          break;
      }
    },
    getDosages: async function(array, target) {
      let dosages = [];
      switch (target) {
        case 'adult':
          for(let i = 0; i <= array.data.length - 1; i++) {
            let response = await getAPIElement('dosierung_erwachsene', array.data[i].dosierungen_erwachsene[array.data[i].dosierungen_erwachsene.length-1].dosierung_erwachsene_id);
            dosages.push(response.data.dosierung);
          }
          break;
        case 'child':
          for(let i = 0; i <= array.data.length - 1; i++) {
            let response = await getAPIElement('dosierung_kinder', array.data[i].dosierungen_kinder[array.data[i].dosierungen_kinder.length-1].dosierung_kinder_id);
            dosages.push(response.data.dosierung);
          }
          break;
        default:
          break;
      }
      return dosages;
    }
  }
}
</script>