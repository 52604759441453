<template>
  <main>
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div v-if="response.ok" class="col-xl-10">
            <div class="card">
              <div class="card-header">
                <h1 class="h4 mt-2">{{items.data[0].name}}</h1>
              </div>
              <div class="card-body">
                <div v-if="typeof(items.precautions) != 'undefined'" class="box">
                  <div class="box-header">
                    <h5 class="mb-0">Vorsichtsmasnahmen</h5>
                  </div>
                  <div class="box-body">
                    <p class="box-text">{{items.precautions.data[0].bezeichnung}}</p>
                  </div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <h5 class="mb-0">Allgemeine Information</h5>
                  </div>
                  <div class="box-collapse">
                    <a class="box-collapse-toggle d-flex align-items-center collapsed" data-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseFour">
                      Zusatzinformationen
                      <i class="fas fa-chevron-down ml-auto"></i>
                    </a>
                    <div class="collapse" id="collapseFour">
                      <div class="box-body">
                        <p class="box-text">{{items.data[0].zusatzinformationen}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-xl-10">
            <div class="card">
              <div class="card-header">
                <h1 class="h4 mt-2">{{items.data[0].name}}</h1>
              </div>
              <div class="card-body">
                <div v-if="typeof(items.precautions) != 'undefined'" class="box">
                  <div class="box-header">
                    <h5 class="mb-0">Vorsichtsmasnahmen</h5>
                  </div>
                  <div class="box-body">
                    <p class="box-text">{{items.precautions.data[0].bezeichnung}}</p>
                  </div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <h5 class="mb-0">Allgemeine Information</h5>
                  </div>
                  <div class="box-collapse">
                    <a class="box-collapse-toggle d-flex align-items-center collapsed" data-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseFour">
                      Zusatzinformationen
                      <i class="fas fa-chevron-down ml-auto"></i>
                    </a>
                    <div class="collapse" id="collapseFour">
                      <div class="box-body">
                        <p class="box-text">{{items.data[0].zusatzinformationen}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {ref} from "vue";

export default {
  name: 'AsyncSinglePathogen',
  props: ['getLocation'],
  async setup(props) {
    var token = document.cookie.match(new RegExp('(^| )' + 'directus_api_auth_token' + '=([^;]+)'));
    if (token) {
      const items = ref(null);
      const response = await fetch('https://directus.comkom.de/items/erreger?filter[status]=published&fields=*.*&filter[name]=' + props.getLocation(), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token[2],
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      if(response.ok) {
        items.value = await response.json();
      } else {
        return {response};
      }

      if(items.value.data[0].vorsichtsmassnahmen != null) {
        var precautions = "";
        items.value.data[0].vorsichtsmassnahmen.forEach(element => {
          precautions += element.id + ',';
        });
        const response2 = await fetch('https://directus.comkom.de/items/vorsichtsmassnahmen/' + precautions + '?filter[status]=published&fields=*.*', {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token[2],
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        });
        items.value.precautions = await response2.json();
      }

      return {items, response};
    }
  }
}
</script>