<template>
  <main>
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div v-if="response_medicaments.ok" class="card">
              <div class="card-header">
                <h1 class="h4 mt-2">{{items.data[0].name}}</h1>
              </div>
              <div class="card-body">
                <div class="box">
                  <div class="box-header">
                    <h5 class="mb-0">General Information</h5>
                  </div>
                  <div class="box-collapse">
                    <a class="box-collapse-toggle d-flex align-items-center collapsed" data-toggle="collapse" href="#collapseEight" role="button" aria-expanded="false" aria-controls="collapseEight">
                      Zusatzinformation
                      <i class="fas fa-chevron-down ml-auto"></i>
                    </a>
                    <div class="collapse" id="collapseEight">
                      <div class="box-body">
                        <p class="box-text">{{items.data[0].zusatzinformationen}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <div class="box-header pb-0">
                    <h5 class="box-title">Dosierung</h5>
                    <ul class="nav nav-tabs nav-justified box-header-tabs" id="age-tab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="adult-tab" data-toggle="tab" href="#adult" role="tab" aria-controls="adult" aria-selected="true">Erwachsen</a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a class="nav-link" id="child-tab" data-toggle="tab" href="#child" role="tab" aria-controls="child" aria-selected="false">Kind</a>
                      </li>
                    </ul>
                  </div>
                  <div class="box-body">
                    <div class="tab-content" id="age-tab-content">
                      <div class="tab-pane fade show active" id="adult" role="tabpanel" aria-labelledby="adult-tab">
                        <p class="box-text" v-for="(item) in items.dosages_adult.data" :key="item.id">{{item.dosierung}}</p>
                      </div>
                      <div class="tab-pane fade" id="child" role="tabpanel" aria-labelledby="child-tab">
                        <p class="box-text" v-for="(item) in items.dosages_child.data" :key="item.id">{{item.dosierung}} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card">
              <div class="card-header">
                <h1 class="h4 mt-2">Fehler</h1>
              </div>
              <div class="card-body">
                <div class="box">
                  <div class="box-header">
                    <p class="mb-0">Es ist ein Fehler beim Abfragen der Daten aufgetreten</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {ref} from "vue";

export default {
  name: 'AsyncSingleMedicament',
  props: ['getLocation'],
  async setup(props) {
    var token = document.cookie.match(new RegExp('(^| )' + 'directus_api_auth_token' + '=([^;]+)'));
    if (token) {
      const items = ref(null);
      const response_medicaments = await fetch('https://directus.comkom.de/items/medikamente?filter[status]=published&fields=*.*&filter[name]=' + props.getLocation(), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token[2],
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      if(response_medicaments.ok) {
        items.value = await response_medicaments.json();
      } else {
        return {response_medicaments};
      }

      var dosage_adult_ids = "";
      items.value.data[0].dosierungen_erwachsene.forEach(element => {
        dosage_adult_ids += element.dosierung_erwachsene_id + ',';
      });
      const response_dosages_adults = await fetch('https://directus.comkom.de/items/dosierung_erwachsene/' + dosage_adult_ids + '?filter[status]=published&fields=*.*&filter[name]=' + props.getLocation(), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token[2],
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      items.value.dosages_adult = await response_dosages_adults.json();

      var dosage_child_ids = "";
      items.value.data[0].dosierungen_kinder.forEach(element => {
        dosage_child_ids += element.dosierung_kinder_id + ',';
      });
      const response_dosages_childs = await fetch('https://directus.comkom.de/items/dosierung_kinder/' + dosage_child_ids + '?filter[status]=published&fields=*.*&filter[name]=' + props.getLocation(), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token[2],
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      items.value.dosages_child = await response_dosages_childs.json();

      return {items, response_medicaments};
    }
  }
}
</script>